import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import {
  FaRegCopyright,
  FaStackOverflow,
  FaTwitter,
  FaLinkedin,
  FaGithubSquare,
  FaRegEnvelope,
  FaRss,
  FaMoon,
  FaSun,
} from "react-icons/fa"

import Header from "./Header"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-top: 120px;
  }
`

const Main = styled.main`
  flex: 1 1 auto;
  min-height: calc(100vh - 340px);
`

const Footer = styled.footer`
  border-top: 5px solid var(--accent);
  flex: 0 0 auto;
  max-height: 200px;
`

const FooterText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  flex-wrap: wrap;
  line-height: 2;
`

const Pipe = styled.span`
  margin: 0 2px;
`
const InnerFlex = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px auto;
  max-width: 320px;
  a {
    font: unset;
    font-size: 32px;
    line-height: 32px;
    padding: 0;
    margin: 0;
  }
`

const DarkSwitcher = styled.button`
  /* Dark mode styles */
  &.dark-switcher {
    color: var(--navLinks);
    position: absolute;
    top: 18px;
    right: 1.0875rem;
    height: 36px;
    background: transparent;
    border: 1px dotted #ccc;
    line-height: unset;
    padding: 0 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out,
      border-color 250ms ease-in-out;
  }
`

const Layout = ({ children }) => {
  const initTheme =
    typeof window !== "undefined" && window.__theme ? window.__theme : null
  const [theme, setTheme] = useState(initTheme)
  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => setTheme(window.__theme)
  }, [])
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              socialLinks {
                linkedin
                stackOverflow
                twitter
                github
                email
              }
            }
          }
          footerIcon: file(relativePath: { eq: "images/gatsby-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 30) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <Container>
              <DarkSwitcher
                className="dark-switcher"
                onClick={e =>
                  window.__setPreferredTheme(
                    theme === "dark" ? "light" : "dark"
                  )
                }
                aria-label={
                  theme === "dark"
                    ? "Click to Disable Dark Mode"
                    : "Click to Enable Dark Mode"
                }
              >
                {theme === "dark" ? (
                  <>
                    Turn off Dark Mode&nbsp;
                    <FaSun />
                  </>
                ) : (
                  <>
                    Turn on Dark Mode&nbsp;
                    <FaMoon />
                  </>
                )}
              </DarkSwitcher>
              <Main>{children}</Main>
              <Footer>
                <FooterText>
                  <FaRegCopyright />
                  <span style={{ marginLeft: "5px" }}>
                    {new Date().getFullYear()} Wesley L. Handy
                  </span>
                  <Pipe>|</Pipe>
                  <Link to="/">wesleylhandy.net</Link>
                  <Pipe>|</Pipe>
                  <InnerFlex>
                    Built using&nbsp;
                    <a
                      href="https://www.gatsbyjs.org"
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "inline-block",
                      }}
                    >
                      <Image
                        fluid={data.footerIcon.childImageSharp.fluid}
                        alt="Gatsby"
                      />
                    </a>
                  </InnerFlex>
                </FooterText>
                <FooterLinks>
                  <a
                    href={data.site.siteMetadata.socialLinks.linkedin}
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                    href={data.site.siteMetadata.socialLinks.stackOverflow}
                    aria-label="Stack Overflow"
                  >
                    <FaStackOverflow />
                  </a>
                  <a
                    href={data.site.siteMetadata.socialLinks.github}
                    aria-label="Github"
                  >
                    <FaGithubSquare />
                  </a>
                  <a
                    href={data.site.siteMetadata.socialLinks.twitter}
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href={data.site.siteMetadata.socialLinks.email}
                    aria-label="email"
                  >
                    <FaRegEnvelope />
                  </a>
                  <a href="/rss.xml" aria-label="RSS Feed">
                    <FaRss />
                  </a>
                </FooterLinks>
              </Footer>
            </Container>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
