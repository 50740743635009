import React from "react"
// import styled from "@emotion/styled"

// const H1 = styled.h1`
//   font-family: "komika_title-axis_regular", sans-serif;
//   font-weight: 400;
//   font-style: normal;
// `

const PageHeading = ({children, style = {}}) => (
    <h1 style={style}>{children}</h1>
)

export default PageHeading