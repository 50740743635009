import React from "react"
// import styled from "@emotion/styled"

// const H2 = styled.h2`
//   font-family: "komika_title-regular", sans-serif;
//   font-weight: 400;
//   font-style: normal;
// `

const SubHeading = ({children, style = {}}) => (
    <h2 style={style}>{children}</h2>
)

export default SubHeading