/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import {
  FaStackOverflow,
  FaTwitter,
  FaLinkedin,
  FaGithubSquare,
  FaRegEnvelope,
} from "react-icons/fa"

const BioCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border: 2px solid #000;
  border-color: var(--border-color);
  margin: 30px auto;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out,
    border-color 250ms ease-in-out;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const BioText = styled.p`
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 767px) {
    margin: 10px 0;
  }
`

const ContactLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto 0 auto 20px;
  height: 160px;
  a {
    font-size: 19px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    font: unset;
  }
  @media screen and (max-width: 767px) {
    flex-direction: row;
    height: auto;
    max-width: 320px;
    width: 100%;
    margin: 20px auto 0 auto;
  }
  @media screen and (max-width: 475px) {
    flex-wrap: wrap;
  }
`

function Bio({ text }) {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const {
          author,
          socialLinks: { twitter, linkedin, stackOverflow, github, email },
        } = data.site.siteMetadata
        return (
          <BioCard>
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author.name}
              style={{
                marginRight: 20,
                marginBottom: 0,
                width: 100,
                borderRadius: `100%`,
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: 100,
              }}
              imgStyle={{
                borderRadius: `50%`,
                marginBottom: 0,
              }}
            />
            <BioText>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    text.replace(author.name, `<strong>${author.name}</strong>`) +
                    "&nbsp;",
                }}
              />
              <a href={twitter}>You should follow him on Twitter</a>
            </BioText>
            <ContactLinks>
              <a href={linkedin} aria-label="LinkedIn">
                <FaLinkedin />
              </a>
              <a href={stackOverflow} aria-label="Stack Overflow">
                <FaStackOverflow />
              </a>
              <a href={github} aria-label="Github">
                <FaGithubSquare />
              </a>
              <a href={twitter} aria-label="Twitter">
                <FaTwitter />
              </a>
              <a href={email} aria-label="email">
                <FaRegEnvelope />
              </a>
            </ContactLinks>
          </BioCard>
        )
      }}
    />
  )
}

Bio.propTypes = {
  text: PropTypes.string,
}

Bio.defaultProps = {
  text: ``,
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(relativePath: { eq: "images/Handy-button.png" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author {
          name
          email
        }
        socialLinks {
          linkedin
          stackOverflow
          twitter
          github
          email
        }
      }
    }
  }
`

export default Bio
